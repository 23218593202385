<template>
  <header class="header">
    <div class="header-main">
      <div class="container">
        <div class="header-main__wrapper">
          <a href="#" @click.prevent="$root.$refs.modal.show(form)" class="header-logo">
            <img class="header-logo__image" src="../assets/images/icons/logo.svg" alt>
            <div class="header-logo__title">
              <div>АВАНГАРД Пискаревский</div>
            </div>
          </a>

          <Slide right :closeOnNavigation="true">
            <header-nav></header-nav>
          </Slide>

          <header-actions></header-actions>
        </div>
      </div>
    </div>

    <header-nav></header-nav>
  </header>
</template>

<script>
import { Slide } from 'vue-burger-menu'
import HeaderActions from "@/components/Header/HeaderActions";
import HeaderNav from "@/components/Header/HeaderNav";

export default {
  name: 'Header',
  components: {HeaderActions, HeaderNav, Slide },

}
</script>
