<template>
  <section class="section" id="contacts">
    <yandex-map id="map" v-bind="map" @map-was-initialized="mapSetup">
      <ymap-marker :coords="map.coords" markerId="123"/>
    </yandex-map>

    <div class="reviews-wrapper">
      <iframe class="reviews-iframe" style="width:100%;height:1300px;border:1px solid transparent;box-sizing:border-box"
              src="https://yandex.ru/maps-reviews-widget/190241823033?comments"></iframe>
    </div>

    <div class="container">
        <div class="row">
          <div class="col-lg-4 col-sm-12">
            <div class="map-form">
              <div class="map-logo">
                <img class="header-logo__image" src="../assets/images/icons/logo-white.svg" alt>
                <div class="map-logo__title">
                  АВАНГАРД<br>Пискаревский
                </div>
              </div>
              <a class="map-phone" href="tel:88127024173">8 (812) 702-41-73</a>
              <div class="map-time">
                <img src="../assets/images/icons/time.png" alt>
                <div>с 9:00 до 21:00 ежедневно</div>
              </div>

              <form @submit.prevent="sendForm">
<!--                <input class="input" v-model="form.name" placeholder="Ваше имя" name="name" required/>-->
                <phone-input v-model="form.phone"/>

                <label class="form-agreement">
                  <input type="checkbox" checked required/>
                  <div class="form-agreement__checkmark">
                    <i class="form-agreement__checkmark-icon"></i>
                  </div>
                  <div class="form-agreement__label form-agreement__label--light">
                    Вы даете согласие на<br>
                    <a href="" @click.prevent="$root.$refs.policy.toggle(true)">
                      обработку персональных данных</a>
                  </div>
                </label>

                <button class="button">Позвоните мне</button>
              </form>
            </div>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
  name: 'Contacts',
  components: { yandexMap, ymapMarker },
  data () {
    return {
      map: {
        settings: {
          apikey: 'bbe37589-35ec-42b6-981f-1029888c5985',
          lang: 'ru_RU'
        },
        coords: [59.987000, 30.240000],
        controls: ['zoomControl', 'fullscreenControl'],
        zoom: 15,
        behaviors: ['LeftMouseButtonMagnifier', 'drag']
      },
      form: {
        phone: '',
        name: 'name',
        order_id: 1,
        subject: 'Форма обратной связи на карте',
        calltouch_id: 34101
      }
    }
  },
  methods: {
    mapSetup (map) {
      map.behaviors.disable('multiTouch')
      map.balloon.open(
        [59.986493, 30.229082],
        `
        <div class="map-baloon">
          <img class="map-baloon__image" src="${require('../assets/images/icons/map-logo.png')}" alt>
          <div class="map-baloon__title">Авангард Пискаревский</div>
          <div class="map-baloon__nav">
            <img src="${require('../assets/images/icons/ym-icon.png')}">
            <a href="https://yandex.ru/maps/2/saint-petersburg/?ll=30.229082%2C59.986493&mode=routes&rtext=~59.986493%2C30.229082&rtt=auto&z=13" target="_blank">Перейти в Яндекс Навигатор</a>
          </div>
        </div>
        `,
        { closeButton: false }
      )
    },

    async sendForm() {
      await this.$root.$refs.modal.submit(this.form);
      this.form.phone = '';
    }
  }
}
</script>
